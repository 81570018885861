<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>加油审核</el-breadcrumb-item>
      <el-breadcrumb-item>加油审核列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card">
      <!-- 使用单位 -->
      <div class="boxs">
        <div class="tops">
          <span>使用单位：</span>
          <el-select v-model="current_org" placeholder="未选择" clearable @clear="qingchu">
            <el-option v-for="item in trashtypeData" :key="item.id" :label="item.node_name" :value="item.id" @click.native="choicedrug"></el-option>
          </el-select>
        </div>
        <!-- 车辆类型 -->
        <div class="tops">
          <span>车辆类型：</span>
          <el-select v-model="type" placeholder="未选择">
            <el-option v-for="item in typelist" :key="item.id" :label="item.name" :value="item.id" @click.native="chetype"></el-option>
          </el-select>
        </div>

        <div class="tops">
          <span>搜索车牌号：</span>
          <el-autocomplete
            :trigger-on-focus="false"
            placeholder="请输入内容"
            v-model="key"
            :fetch-suggestions="salesperson"
            value-key="auto_number"
            @select="XiaohandleSelect($event, '车牌号')"
          ></el-autocomplete>
        </div>
        <el-button type="primary" @click="btn">重新选择</el-button>
      </div>
      <div class="herd"></div>
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column label="车牌号">
          <template slot-scope="scope">
            <div id="test">{{ scope.row.auto_number }}</div>
          </template>
        </el-table-column>
        <el-table-column label="汽油单价">
          <template slot-scope="scope">{{ scope.row.unit_price }}元</template>
        </el-table-column>
        <el-table-column label="加油总额">
          <template slot-scope="scope">{{ toFixed(scope.row.total_price) }}元</template>
        </el-table-column>
        <el-table-column label="加油量">
          <template slot-scope="scope">{{ scope.row.refueling_volume }}L</template>
        </el-table-column>
        <el-table-column prop="refueling_time" label="加油时间"></el-table-column>
        <el-table-column label="公里数(Km)">
          <template slot-scope="scope">{{ toFixed(scope.row.kilometre) }}</template>
        </el-table-column>
        <el-table-column label="里程数">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px" :src="scope.row.kilometre_files" :previewSrcList="[scope.row.kilometre_files]"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="小票">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px" :src="scope.row.refueling_files" :previewSrcList="[scope.row.refueling_files]"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="220px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="success" @click="tongguo(scope.row.id)">通过</el-button>
            <el-button size="mini" type="primary" @click="bianji(scope.row.id)">编辑</el-button>
            <el-button size="mini" type="danger" @click="endshan(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>

    <el-dialog title="审核信息修改" :visible.sync="endbianjitan" width="600px">
      <!-- <el-form :model="Detail" ref="Detail">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="8">
            <el-form-item label="车牌号">
             <div class="el-select"><el-input v-model="Detail.auto_number" disabled></el-input></div>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
      </el-form> -->
      <el-form ref="Detail" :model="Detail" label-width="100px">
        <el-form-item label="车牌号">
          <div class="el-select"><el-input v-model="Detail.auto_number" disabled></el-input></div>
        </el-form-item>
        <el-form-item label="汽油单价">
          <div class="el-select">
            <el-input v-model="Detail.unit_price" placeholder="汽油单价">
              >
              <i slot="prefix" class="iconfont icon-jiage-copy"></i>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label="加油总金额">
          <div class="el-select">
            <el-input v-model="Detail.total_price" placeholder="加油总金额">
              >
              <i slot="prefix" class="iconfont icon-jiage-copy"></i>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label="当前公里数">
          <div class="el-select"><el-input v-model="Detail.kilometre" placeholder="当前公里数">></el-input></div>
        </el-form-item>
        <el-form-item label="加油量(L)">
          <div class="el-select"><el-input v-model="Detail.refueling_volume" placeholder="加油量">></el-input></div>
        </el-form-item>
        <el-form-item label="加油时间">
          <el-date-picker v-model="Detail.refueling_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="加油时间"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="cancelfuns()">取 消</el-button>
          <el-button type="primary" @click="amend('Detail')">确认修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import { Getxiangmu, Gettong, GetRefuelingList, Endshanchu, GetautoList, Endbianji, Getdang } from '../../../api/car'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      endbianjitan: false,
      current_org: '', // 选中使用单位的值
      trashtypeData: [], // 使用单位数据
      type: '', // 车辆类型
      tableData: [], // 列表
      id: '', // 点击通过需要传过去的id
      status: 0,
      endid: '', // 删除时候传过去的id
      userlist: [], // 搜索
      key: '', // 搜索车辆
      auto_id: '', // 车辆id
      getxiangid: '',
      // 车辆类型
      typelist: [
        { name: '运营车辆', id: 1 },
        { name: '管理车辆', id: 2 }
      ],
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      // 编辑过后的数据
      Detail: {
        auto_id: '', // 车辆id
        unit_price: '', // 汽油单价
        total_price: '', // 加油总金额
        kilometre: '', // 当前公里数
        refueling_volume: '', // 加油量
        refueling_time: '', // 加油时间
        refueling_files: '', // 加油小票
        kilometre_files: '', // 里程表照片
        from_source: 'pc',
        auto_number: '',
        id: ''
      }
    }
  },
  created() {},
  mounted() {
    this.Getxiangmulist() // 拉取使用单位
    this.getrefuelinglistfun() // 拉取审核列表
  },
  methods: {
    toFixed(val) {
      return parseFloat(val).toLocaleString('en', {
        // minimumFractionDigits: 2,
        // maximumFractionDigits: 2
      })
    },
    // 重新选择拉取数据
    btn() {
      this.auto_id = ''
      this.type = ''
      this.current_org = ''
      this.getrefuelinglistfun()
      console.log(11)
    },
    // 删除
    endshan(e) {
      this.endid = e
      // console.log(this.endid)
      this.Endshanchu()
    },

    // 通过
    tongguo(e) {
      // console.log(e)
      this.id = e
      this.status = 1
      console.log(this.status)
      this.Gettongguo()
      this.getrefuelinglistfun()
    },
    // 编辑弹窗
    bianji(e) {
      // console.log(e)
      this.getxiangid = e
      this.Getdandtiao()
      this.endbianjitan = true
    },

    // 选中使用单位后需要重新拉取列表
    choicedrug() {
      this.getrefuelinglistfun()
    },
    // 选中作业车辆
    chetype() {
      this.getrefuelinglistfun()
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.getrefuelinglistfun()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.getrefuelinglistfun()
    },
    // 输入车辆匹配数据
    salesperson(queryString, cb) {
      this.getallfun(queryString, cb)
      this.getrefuelinglistfun()
    },
    // 搜索车辆选中
    XiaohandleSelect(e, term) {
      this.auto_id = e.id
      this.getrefuelinglistfun()
    },
    // 取消编辑
    cancelfuns() {
      this.endbianjitan = false
    },
    // 确认修改
    amend(Detail) {
      this.$refs[Detail].validate(valid => {
        if (valid) {
          // console.log(valid)
          this.Endbianjixinxi(this.Detail)
          this.endbianjitan = false // 成功之后关闭弹窗
          this.getrefuelinglistfun() // 重新拉取列表
        } else {
        }
      })
    },

    qingchu() {
      this.current_org = ''
      this.getrefuelinglistfun()
    },

    //////////////////请求区/////////////////////////

    // 获取项目
    async Getxiangmulist() {
      const { data } = await Getxiangmu()
      this.trashtypeData = data.data
    },

    // 审核列表
    async getrefuelinglistfun() {
      const { data } = await GetRefuelingList({ auto_id: this.auto_id, type: this.type, current_org: this.current_org, ...this.pageData, status: 0 })
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },

    // 通过审核
    async Gettongguo() {
      const { data } = await Gettong({ id: this.id, status: this.status })
      // console.log(data)
      this.getanpostfun(data, '通过', this.getrefuelinglistfun())
    },
    // 删除
    async Endshanchu() {
      const { data } = await Endshanchu({ id: this.endid })
      this.getanpostfun(data, '删除', this.getrefuelinglistfun())
    },
    async getallfun(key, cd) {
      // 获取车辆
      const { data } = await GetautoList({ key: key, page: 1, size: 100 })
      this.userlist = data.data.list
      cd(data.data.list)
    },
    // 编辑
    async Endbianjixinxi(v) {
      let ss = JSON.parse(JSON.stringify(v))
      ss.unit_price = JSON.stringify(ss.unit_price * 100)
      ss.total_price = JSON.stringify(ss.total_price * 100)
      const { data } = await Endbianji(ss)
      this.getanpostfun(data, '修改', this.getrefuelinglistfun())
      // console.log(data)
    },

    // 拉取单条数据回显
    async Getdandtiao() {
      const { data } = await Getdang({ id: this.getxiangid })
      // console.log(data.data.info)
      this.Detail.auto_id = data.data.info.auto_id
      this.Detail.unit_price = data.data.info.unit_price / 100
      this.Detail.total_price = data.data.info.total_price / 100
      this.Detail.kilometre = data.data.info.kilometre
      this.Detail.refueling_volume = data.data.info.refueling_volume
      this.Detail.refueling_time = data.data.info.refueling_time
      this.Detail.refueling_files = data.data.info.refueling_files
      this.Detail.kilometre_files = data.data.info.kilometre_files
      this.Detail.auto_number = data.data.info.auto_number
      this.Detail.id = data.data.info.id
    },

    // 公共方法
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>

<style scoped>
.boxs {
  display: flex;
}
.tops {
  width: 320px;
}
</style>